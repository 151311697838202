.accordion-button{
  padding: 10px 59px 10px 0 !important;
  background-color: transparent !important;
  color: #000 !important;
}
.accordion-item h2{
  line-height: 25px ;
}
.accordion-item{
  background-color: transparent !important;
}
.accordion-header{
  margin: 0;
}
.plan-desktop{
  display: flex;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}
.terms h2{
  font-size: 24px;
  color: #2e2e2e;
  text-align: left;
}
.contact-us-form .log-in-form{
  max-width: 100%;
}
.terms h1{
  font-size: 36px;
  color: #2e2e2e;
}
.plan .swiper-ver-slide{
  height: 100%;
}
body{
  font-family: 'Inter', sans-serif !important; 
  overflow-x:hidden;
}
html{
  overflow-x:hidden;
  box-sizing: border-box;
}
/* section{
  padding: 80px 0;
} */
footer{
  position: relative;
  width: 100%;
}
.footer-section{
  display: flex;
    align-items: flex-end;
}
.quote{
  border-radius: 12px;
  padding: 11px 35px;
  border:  1px solid #B090FF;
  background: transparent;
  transition:all 0.5s ease ease-in-out;
  /* background-image: linear-gradient(#8A63F4, #B090FF), linear-gradient(91.48deg, #8A63F4, #B090FF);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset; */
}
.terms{
  padding-top: 90px;
}
.nav-link a{
  color: #000;
}
.nav-link a:hover {
  color: #B090FF;
}
.fixed-header{
  background: #fff;
  box-shadow: 0px 16px 33px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}
.navbar{
  transition: 0.5s;
}
.fixed-header .quote{
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
}
.fixed-header .gredient-text{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  background: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  background-clip: text;
  text-fill-color: #fff;
  color: #fff;
}
.gredient-text{
  font-weight: 600;
font-size: 16px;
line-height: 16px;
text-align: center;
text-transform: capitalize;
background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.nav-link{
  font-weight: 500;
font-size: 16px;
line-height: 19px;
color: #000000;
}
footer{
  background: #0B1632;
  padding: 60px 0 10px 0;
}
footer a{
  display: block;
}
footer h6{
  padding-bottom: 25px;
}
footer p{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.3px;
color: #64626A;
padding-top: 20px;
}
.footer a{
  font-weight: 300;
font-size: 15px;
line-height: 18px;
letter-spacing: -0.204545px;
color: #9d9caf !important;
padding-bottom: 25px;
}
footer h6{
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.231818px;
  color: #cebcff;
}
footer .quote{
  border: 1px solid #cebcff; 
}
footer .quote .gredient-text{
  background: #cebcff;
  font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
footer ul li img{
  /* padding-bottom: 27px; */
}
footer input{
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 12px;
  width: calc(100% - 80px);

}
footer input:focus{
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #FFFFFF;
}
.gr-send{
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
border: 1px solid rgba(255, 255, 255, 0.62);
border-radius: 5px 5px 0px 0px;
width: 40px;
height: 40px;
}
.f-input{
  display: flex;
  margin-top: 68px;
}
footer input{
  color: #fff;
}
.gr-send svg path{
  fill:#fff;
  stroke:#fff;
}

.fotter-bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 40px  100px 0 100px; */
}
.fotter-bottom ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

.fotter-bottom ul li {
  color: #fff;
  display: inline-block;
}
.page-links li{
  padding-right: 40px;
  font-weight: 400;
font-size: 15px;
line-height: 20px;
letter-spacing: -0.204545px;
color: #FFFFFF;
}
.social-icon li{
  padding:0 10px ;
}
.social-icon li i{
  font-size: 22px;
}
.fotter-bottom ul li a{
  padding-bottom: 0;
}



/* .faqsec{
  padding-bottom: 150px;
} */
footer section{
  padding: 0;
}
.faqsec section{
  padding: 0;
}
.banner-sec{
  display: flex;
  align-items: center;
}
.banner-position-img{
  position: relative;
  width: fit-content;
  margin: auto;
  z-index: 9;
}
.topimg{
  position: relative;
  z-index: 9;
  width: 80%;
  animation: bounce 1.5s ease infinite alternate;
  
}
.bgvector{
  bottom: -113px;
    left: -6px;
    z-index: 1;
    width: 129%;
}
.bg-circle{
  width: 200px;
height: 200px;
left: 0px;
top: -100px;
background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
opacity: 0.6;
filter: blur(30px);
border-radius: 50%;

}
.behindimg ,.bgvector,.bg-circle{
  position: absolute;
 
}
.behindimg,.topimg{
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.61);
backdrop-filter: blur(21px);
padding: 16px;
}
.behindimg{
  top: -84px;
    left: 50%;
    z-index: 2;
    height: 100%;
    animation: bounce 1.5s ease infinite alternate-reverse;
    animation-delay: 1s;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.fp-watermark{
  display: none;
}
#fp-nav{
  display: none !important;
}
.banner-sec{
  margin-top: 0px;
  /* height: 100vh; */
  background: linear-gradient(180deg, #EAE2FF 0%, rgba(234, 226, 255, 0) 100%);
}
h1{
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #2A303E;
  margin: 0;
}
.banner-sec p{
  font-weight: 400;
font-size: 20px;
line-height: 28px;
color: rgba(31, 31, 57, 0.5);
padding-bottom: 38px;
}
.banner-sec ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.banner-sec ul li{
  display: inline-block;
  margin-right: 34px;
}

.vertical-slider img{
  max-width: 400px;
  
}
.vertical-slider{
  display: flex;
  align-items: center;
  
}
.swiper-ver-slide{
  height: calc(100vh - 90px);
  display: flex !important;
  align-items: center;
  justify-content: center;
}








.swiper-ver-slide h2{
  font-weight: 500;
  font-size: 38px;
  line-height: 46px;
  text-align: start;
  color: #2A303E;
  margin-bottom: 11px;
}
.swiper-ver-slide p{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;

}
.swiper-ver-slide ul{
list-style-type: none;
padding: 0;
}
.swiper-ver-slide li{
display: inline-block;
padding-right: 24px;
}
.swiper-ver-slide ul li img{

}












.plan{
  /* height: calc(100vh - 90px); */
  display: flex;
  align-items: center;
}
h2{
  font-weight: 500;
font-size: 38px;
line-height: 46px;
text-align: center;
letter-spacing: 1px;
color: #2A303E;
}
.plan ul{
  list-style-type: none;
}
.plan .gredient-text{
  font-weight: 600;
font-size: 14px;
line-height: 17px;
}
.plan .toggle-switch{
  display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    /* margin-bottom: 50px; */
}
.accordion-button{
  font-weight: 500;
}
.pancard-gap{
  column-gap: 30px;
  justify-content: center;
}
.app-image{
  width: 150px;
}
img.app-image {
  width: 150px;
}
.plan-card{
  background: #FFFFFF;
border: 2px solid #D1D0D6;
box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.08);
border-radius: 12px;
padding: 24px;
min-height: 409px;
display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 360px;
}
.plancard{
  max-width: 360px;
}
.plan-card h5{
  font-weight: 700;
font-size: 24px;
line-height: 29px;
letter-spacing: 0.4px;
color: #000000;
margin: 0;
padding: 0;
}
.plan-card h4{
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: right;
  letter-spacing: 0.6px;
  color: #3B3A40;
  margin: 0;
  padding: 0;
}
.plan-card-header{
  height: 75.81px;
  border-bottom: 1px dashed #D1D0D6;
  margin-bottom: 26px;
}
.plan-card-header ul{
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin:0;
}
.plan-card-header ul li span{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #64626A;
}
.condition li{
  font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #64626A;
position: relative;
padding-bottom: 10px;
}
.condition li:before{
  content: "";
  background: url(../../assets/images/right.webp
  );
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position-x: left;
    background-size: initial;
    left: -25px;
}
.start-free{
  width: 100%;
  border-radius: 10px;
}
.buy-now{
  color: #B3B1B7;
  font-weight: 700;
font-size: 16px;
line-height: 19px;
text-align: center;
letter-spacing: 0.2px;
width: 100%;
border: none;
background: transparent;
margin-top: 24px;
}
.condition {
  display: block !important;
  padding-left: 25px !important;
}
.condition li{
  display: block !important;
}






.faqsec{
  /* height: calc(100vh - 90px); */
  display: flex;
  align-items: center;
  position: relative;
}
.recommended{
  position: absolute;
  text-align: center;
  width: 100%;
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
  border-radius: 12px 12px 0px 0px;
  top: -26px;
  left: 0px;
  outline: 2px solid #B090FF;
  color: #fff;
}
.center-card .plan-card{
  border-radius: 0px 0px 12px 12px;
  border: 2px solid #B090FF;
}
.left-bottom,.top-right{
  position: absolute;
}
.left-bottom{
  bottom: 0;
  left: 0;
  background: linear-gradient(136.02deg, rgba(245, 102, 205, 0.63) -19.59%, rgba(174, 142, 255, 0) 106.01%, rgba(174, 142, 255, 0) 106.01%);
opacity: 0.5;
filter: blur(225px);
border-radius: 41px;
height: 50vh;
width: 40vw;
}
.top-right{
  top: 0;
  right: 0;
  background: linear-gradient(136.02deg, rgba(136, 126, 249, 0.63) -19.59%, rgba(174, 142, 255, 0) 106.01%, rgba(174, 142, 255, 0) 106.01%);
  opacity: 0.5;
  filter: blur(225px);
  border-radius: 41px;
  width: 23vw;
  height: 45vh;
}
.faq-row-wrapper{
  background: transparent !important;
}
.elips-img{
  position: relative;
}
.bottom-side-ellips ,.up-side-ellips{
  width: 64.94px;
height: 64.94px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.bottom-side-ellips{
  background: #29BB89;
  position: absolute;
  bottom: 15%;
  right: 5%;
}
.up-side-ellips{
  background: #FF83AC;
  position: absolute;
  top: 10%;
    left: 5%;
}
.service-ellips{
  background: #1789FB;
  width: 43.29px;
  height: 43.29px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.customer-service{
  display: flex;
  align-items: center;
  column-gap: 13px;
  width: fit-content;
  padding: 24px;
  background: #FFFFFF;
box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.04);
border-radius: 10px;
position: absolute;
bottom: 10%;
    left: -10%;
}
.customer-service p{
  margin: 0;
  font-weight: 400;
font-size: 14px;
line-height: 19px;
letter-spacing: 0.5px;
color: #000000;
}
.orange-dot{
  width: 21.65px;
height: 21.65px;
background: #FF8303;
border-radius: 50%;
position: absolute;
right: 35%;
    bottom: -20%;
}
.green-dot{
  width: 10.82px;
  height: 10.82px;
  background: #29BB89;
  border-radius: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.elips-image{
  background: #C4C4C4;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 50%;
}
.line-bottom{
  position: relative;
  text-align: left;
}
.line-bottom:before{
  position: absolute;
  content: "";
  width: 150px;
  height: 2px;
  background-color: #FF884B;
  bottom: -15px;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c{
  border-bottom: 1px solid #DDDDDD !important;
}
.position-ellips{
  width: fit-content;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
}
.faq-right{
  /* height: calc(100vh - 90px); */
}
.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY.styles_expanded__3elPy .styles_icon-wrapper__2cftw{
  color: #FF884B;
}











.slider .slick-slide {
  border: 0;
  z-index: 1;
  height: 500px;
  position: relative;
  background-color: #DDD;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  align-items: center;
}
.slider .slick-slide:before {
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
.slider .slick-slide h1 {
  font-size: 60px;
  font-weight: 700;
}
.slider .slick-slide p {
  margin: 0 0 15px;
}
.slider .slick-slide.text-left:before {
  background: linear-gradient(to left, rgba(249, 245, 245, 0) 20%, #fafafa);
}
.slider .slick-slide.text-right:before {
  background: linear-gradient(to right, rgba(249, 245, 245, 0) 20%, #fafafa);
}

.slick-dots {
  text-align: left;
  top: calc(50% - 15px);
}
.slick-dots li {
  width: 5px;
  height: 30px;
  display: block;
  margin: 0 0 5px;
  background: #d9534f;
  transition: all 0.5s ease;
}
.slick-dots li.slick-active {
  width: 15px;
}
.slick-dots li button {
  display: none;
}
/* .sliderss{
  height: 100vh;
} */

/* animation */
.behindimg {
  filter: blur(3px);
  transition: 1s;
}
.behindimg:hover{
  filter: blur(0px);
  z-index: 10;
  transition: 0.1s;

}
.gredient-left{
  background: linear-gradient(48.02deg,rgba(140,102,245,.63) -38.59%,rgba(174,142,255,0) 70.01%,rgba(174,142,255,0) 133.01%);
    -webkit-filter: blur(225px);
    filter: blur(147px);
    height: 50%;
    left: 0;
    position: absolute;
    top: 50%;
    width: 50%;
    transform: translate(0, -50%);
}
.banner-position-img.active  .topimg{
  filter: blur(3px);
  transition: 1s;
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
 }
 
 .drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
 }

 .drop-in-3 {
  animation: drop-in 1800ms ease 500ms backwards;
 }
 
 @keyframes drop-in {
  from {
   opacity: 0;
   transform: translateY(-100px);
  }
  to {
   opacity: 1;
   transform: translate(0px);
  }
 }
 .quote:hover{
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
  
 }
 .quote:hover .gredient-text{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  background: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  background-clip: text;
  text-fill-color: #fff;
  color: #fff;
}
.fixed-header .quote:hover{
  border:  1px solid #B090FF;
  background: transparent;
  
 }
 .fixed-header .quote:hover .gredient-text{
  font-weight: 600;
font-size: 16px;
line-height: 16px;
text-align: center;
text-transform: capitalize;
background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.social-icon a:hover{
  color: #8A63F4 !important;
}

@keyframes bounce {
  0% {
  }
  100% {
    transform:translateY(-20px);
  }
}
.plan-card:hover{
  border: 2px solid #B090FF;
}








.plan-mobile .react-tabs__tab-panel--selected {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  
}
.plan-mobile .react-tabs__tab{
 border-bottom: 2px solid transparent;
 font-weight: 700;
}
.plan-mobile .react-tabs__tab--selected {
  background: #fff;
  border-color: transparent;
  border-width: 2px;
  border-bottom: 2px solid transparent;
  color: #B090FF;
  border-radius: 5px 5px 0 0;
 
}
.plan-mobile .react-tabs__tab-list {
  border-bottom: 1px solid transparent;
  margin: 0 0 10px;
  padding: 0;
  text-align: center;
}

.plan-mobile{
  display: none !important;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: fit-content !important;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  right: 0;
  height: fit-content;
  right: -7%;
  transform: rotate(90deg);
}
.slick-dots li {
  width: 5px;
  height: 30px;
  display: block;
  margin: 0 0 5px;
  background: transparent !important;
  transition: all 0.5s ease;
}









.hero {
  padding: 20px;
  height: 100vh;
  min-height: 500px;
  background: #000a;
  color: #fff;
  display: flex;
  align-items: center;
}

.hero-wrap {
  height: 400px;
  width: 100%;
  background: #000a;
  display: flex;
  padding: 20px;
  position: relative;
}

.col {
  width: 50%;
}

.hero__left h3 {
  font-size: 45px;
}

.hero__right {
  position: absolute;
  right: 20px;
  height: 100%;
  padding-left: 20px;
}

.hero__item {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #111;
  border: 4px solid #333;
  height: 360px;
  margin-bottom: 40px;
  transition: all 0.4s ease-out;
  font-size: 30px;
}
.hero__item:last-child {
  margin: 0;
}

.hero__item.active {
  border: 4px solid #0047ffaa;
  box-shadow: 0 0 20px #0332ad;
}
.swiper-ver-slide .animate-img{
  transition: all 1.5s ease-in-out;
  transform: scale(0.9, 0.9);
  opacity: 0;
}
.slick-active .animate-img{
  transform: scale(1, 1);
  opacity: 1;

}

.transform-slide h2{
 
  font-weight: 500;
    color: #000;
    font-size: 55px;
    line-height: 1.18em;
    margin: 25px 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    animation-delay: .24s;
    animation: qodef-content-fade-out .5s forwards cubic-bezier(.4,0,.2,1);
  
    animation-delay: .24s;
   
}
.para-head{
  /* max-height: 135px; */
  overflow: hidden;
}
.slick-active .transform-slide h2{
  animation: qodef-content-fade-in .72s forwards cubic-bezier(.4,0,.2,1);
  transform: translateY(100%);
  
}
@keyframes qodef-content-fade-in {
  0% {
      opacity: 0;
      transform: translateY(100%)
  }

  100% {
      opacity: 1;
      transform: translateY(0)
  }
}
@keyframes qodef-content-fade-ins {
  0% {
      opacity: 0;
      transform: translateY(100%)
  }

  100% {
      opacity: 1;
      transform: translateY(0)
  }
}
@keyframes qodef-content-fade-out {
  0% {
      opacity: 1;
      transform: translateY(0)
  }
 

  100% {
      opacity: 0;
      transform: translateY(-100%)
  }
}
@keyframes qodef-content-fade-outs {
  0% {
      opacity: 0.5;
      transform: translateY(0)
  }
 

  100% {
      opacity: 0;
      transform: translateY(-100%)
  }
}

.transform-slide ul{ 
  opacity: 0;
  transform: translateY(70%);
  animation: qodef-content-fade-outs 1.5s forwards ease;
}
.slick-active .transform-slide ul{
  animation: qodef-content-fade-ins 1s forwards ease .48s;
  opacity: 0;
}


.transform-slide p{
  transform: translateY(-100px);
  
}
.slick-active .transform-slide p{
  animation-delay: .660s;
  animation: qodef-content-fade-in 1.2s forwards cubic-bezier(.4,0,.2,1);

}
footer .app-link a{
  width: fit-content;
  margin: auto 
}
footer .app-link{
  list-style-type: none;
}
.para-div{
  margin-bottom: 50px;
  overflow:hidden;
  
}
.ellipse-container{
  height: 100%;
    display: flex;
    align-items: center;
}
.toggle-switch li{display: none; }
.open{
  background: #fff;
}
.text2{
  display: none;
}

.fp-viewing-Slider .navbar,.fp-viewing-Plan .navbar,.fp-viewing-Faq .navbar,.fp-viewing-Contact .navbar ,.fp-viewing-undefined .navbar,.fp-viewing-Features .navbar,.fp-viewing-4 .navbar{
  background: #fff;
    box-shadow: 0 16px 33px rgba(0,0,0,.08);
    
}
.fp-viewing-Plan ._plan,.fp-viewing-Faq ._faq,.fp-viewing-Features ._features,.fp-viewing-Home ._overview{
  color: #9F7CFB !important;
    
}


.user-joined{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 15px;
  row-gap: 15px;
}
.login{
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: #E7E5FE;
    padding-top: 100px;
    padding-bottom: 20px;
}
.pass-show{
  color: #84818A;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0 ,-50%);
    z-index: 8;
    cursor: pointer;
}
.image-group img{
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
.image-group img:nth-child(2),.image-group img:nth-child(3){
  margin-left: -10px;
}
.login p{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
 color: #47464A;
 padding: 16px 0 32px 0;
}
.login h2{
  font-weight: 600;
font-size: 36px;
line-height: 40px;
color: #202020;
padding-top: 15px;
}
.login-text{
  max-width: 480px;
}
.log-in-form input,.log-in-form textarea {
  display: block;
  background: transparent;
  position: relative;
  z-index: 3;
border: 1px solid #DCDBDD;
border-radius: 12px;
padding: 15px;
width: 100%;
margin-bottom: 24px;
font-weight: 500;
font-size: 14px;
color: #202020;
transition: all .3s ease;
}
.log-in-form input:focus ,.log-in-form textarea:focus {
  outline: none;
}
.log-in-form p{
  width: fit-content;
}
.login-page-submit{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.signin-button{
  color: #fff;
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
border-radius: 12px;
padding: 14px 58px;
display: inline-block;
}
.f-password{
  border: none;
  background: transparent;
  font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #47464A;
}
.log-in-form p{
  font-weight: 400;
font-size: 12px;
line-height: 17px;
color: #84818A;
text-align: center;
padding: 0;
margin: auto;
}
.log-in-form p .gredient-text{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.log-in-form{
  background: #FBFCFC;
border-radius: 32px;
padding: 38px 32px;
max-width: 400px;
width: 100%;
margin: 0 0 0 auto;
}
.email-lable{
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 18px;
  left: 10px;
  font-size: 12px;
  font-weight: bold;
  background: #FBFCFC;
  padding: 0 10px;
  color: #999;
  transition: all .3s ease
}
.contact-us-left{
  background-image: url(../../assets/images/contact-us.webp) ;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
}
.contact-us-left .social-icon li{
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
  margin-right: 5px;
  text-align: center;
  border-radius: 5px;
  width: 40px;
  padding: 0;
}
.contact-us-left .social-icon li:hover{
  background: linear-gradient(91.48deg, #e1dbf1 0.29%, #b7a5e5 100%);
}
.contact-us-left .social-icon li a{
  padding: 7px;
  display: flex;
  justify-content: center;
}
.login-page-content{
  row-gap: 40px;
}
 .log-in-form input:focus+label , .log-in-form  input:valid + label{
  top: -10px;
  z-index: 4;
  
}
.log-in-form textarea:focus~.email-lable , .log-in-form  textarea:valid~.email-lable{
  top: -10px;
  z-index: 4;
  
}
.model.modal-body {
  position: relative;
  flex: 1 1 auto;
  --mdb-gutter-x: 1.5rem;
  padding: 0rem;
  padding-right: calc(var(--mdb-gutter-x) * 0.5);
  padding-left: calc(var(--mdb-gutter-x) * 0.5);
}
.contact-form .logo{
 max-width: 120px; 
 max-height: 50px;
 margin-top: 20px;
}
.contact-us-left p{
  font-weight: 700;
  padding-top: 10px;
}
.contacy-us-right p{
  padding-left: 32px;
}
.contacy-us-right h2{
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 5px;
  padding-left: 32px;
}
.log-in-form h2{
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
text-align: left;
  color: #202020;
  margin: 0;
  padding-bottom: 30px;
}
.centerline{
  border-bottom: 1px solid #DCDBDD;
  margin: 34px 0;
}
.centered-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-weight: 400;
font-size: 14px;
background: #FBFCFC;
padding: 0 12px;
color: #84818A;
}
.Google{
  font-weight: 500;
font-size: 14px;
line-height: 21px;
color: #202020;
background: #FFFFFF;
border: 1px solid #DCDBDD;
border-radius: 12px;
padding: 16px 53px ;
display: flex;
align-items: center;
column-gap: 10px;
margin: auto;
margin-bottom: 40px;
}
.error-message{
  font-weight: 500;
font-size: 14px;
line-height: 21px;
}


.form-input-row{
  column-gap: 10px;
}
.form-input-row .pass-show{
  top: calc(50% - 12px);
}
.term-checkbox{
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 25px 0 48px 0;
}
.term-checkbox p{
  font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #84818A;
text-align: left;
margin: 0;
}
.term-checkbox p .gredient-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}




input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 .OtpInput input{
  padding: 0;
  max-width: 48px !important;
  max-height: 48px;
  width: 100% !important;
  height: 48px ;
  border: 1px solid #B090FF;
}
.OtpInput>div{
  justify-content: center;
}
.form-input-row .position-relative{
  width: calc(50% - 5px);
}
#steptwo{
  max-width: 600px;
}
.term-checkbox input {
  display: flex;
  appearance: none;
  background:  #fff;
  height: 20px;
  width: 20px;
  border-radius: 20%;
  padding: 0;
  margin: 0;
}
.term-checkbox input::after {
  content: '✔';
  margin: auto;
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.term-checkbox input::after:hover {
  color: #fff;
}
.term-checkbox input:checked::after {
  color: #fff;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.term-checkbox input:checked{
  background:  linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);

}








.loader-body{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 32px;
  justify-content: center;
  flex-direction: column;
    background: #fff;
    z-index: 9;
    opacity: 0.9;
}
.loader-circle-2 {
	position: relative;
	width: 70px;
	height: 70px;
	display: inline-block;
  z-index: 9;
}
.loader-circle-2:before,
.loader-circle-2:after {
	content: "";
	display: block;
	position: absolute;
	border-width: 5px;
	border-style: solid;
	border-radius: 50%;
}
.loader-circle-2:before {
	width: 70px;
	height: 70px;
	border-bottom-color: #009688;
  border-style: solid;
  border-radius: 50%;
  background: linear-gradient(white, white) padding-box,
  linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%) border-box;
  border: 2px solid transparent;
	border-top-color: transparent;
	border-left-color: transparent;
  border-top: none;
  border-left: none;
	animation: loader-circle-2-animation-2 1s linear infinite;
}
.loader-circle-2:after {
	width: 40px;
	height: 40px;
  border-style: solid;
  border-radius: 50%;
  background: linear-gradient(white, white) padding-box,
  linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%) border-box;
  border: 2px solid transparent;
	border-top-color: transparent;
	border-left-color: transparent;
  border-top: none;
  border-left: none;
	top: 22%;
	left: 22%;
	animation: loader-circle-2-animation 0.85s linear  infinite;
}
@keyframes loader-circle-2-animation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
@keyframes loader-circle-2-animation-2 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}







.quote {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid #8A63F4;
}


@keyframes criss-cross-left {
  0% {
    left: -10px;
  }
  50% {
    left: 35%;
    width: 50px;
    height: 50px;
  }
  70%{
        left: 20%;
  }100% {
    background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
    left: 110%;
    width: 375px;
    height: 375px;
 

  }
}
@keyframes criss-cross-left2 {
  0% {
    left: -10px;
  }
  50% {
    left: 35%;
    width: 50px;
    height: 50px;
  }
  70%{
        left: 20%;
  }100% {
    background: linear-gradient(91.48deg, #fff 0.29%, #fff 100%);
    left: 110%;
    width: 375px;
    height: 375px;
 

  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  30% {
    right: 30%;
    width: 20px;
    height: 20px;
  }
  40%{
     right: 20%;
  }
  100% {
    right: 80%;
    width: 125px;
    height: 125px;
  }
}
.quote {
  position: relative;
  color: #8556b0;
}

.quote:before, .quote:after {
  position: absolute;
  top: 50%;
  content: '';
  width: 20px;
  height: 20px;
  background-color: #7038a2;
  border-radius: 50%;
}


.quote:before {
  left: -20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  
}

.quote:after {
  background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
  right: -20px;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.quote:hover {
    color: #fff;
}

.quote:hover:before {

  -webkit-animation: criss-cross-left 1.2s both;
          animation: criss-cross-left 1.2s both;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  
}

.quote:hover:after {
  
  -webkit-animation: criss-cross-right 1.2s both;
          animation: criss-cross-right 1.2s both;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.quote .gredient-text{
  position: relative;
  z-index: 8;
}

.modal {
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;
  background: #00000094;
}
.navbar.open .navbar-collapse{
  padding-top: 20px;

}
.navbar.open .navbar-collapse ul{
  padding-bottom: 20px;
}
.navbar.open .navbar-collapse li{
  padding: 10px 0;
  border-bottom: 1px solid #9f7cfb36;
}
.mobile-contact-form{
  display: none;
}


.clone-model .quote {
  width: fit-content;
  margin: auto;
}
.clone-model p{
  font-weight: 400;
font-size: 12px;
line-height: 20px;
text-align: center;
color: #979797;
max-width: 210px;
margin: auto;
padding-bottom: 12px;
}
.clone-model h5{
  font-weight: 600;
font-size: 16px;
line-height: 16px;
text-align: center;
text-transform: capitalize;
color: #000000;
margin: auto;
padding-bottom: 12px;
}
