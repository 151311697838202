@media only screen and (max-width: 1199px) {
    #steptwo .form-input-row{
        display: block !important;
    }
    #steptwo .form-input-row .position-relative {
        width: 100%;
    }
    .elips-image {
        background: #C4C4C4;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        width: 75%;
    }
    .banner-sec ul li {
        display: inline-block;
        margin-right: 10px;
    }
    .bottom-side-ellips {
        background: #29BB89;
        position: absolute;
        bottom: 15%;
        right: 24%;
    }
    .customer-service {
        display: flex;
        align-items: center;
        column-gap: 13px;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
        background: #FFFFFF;
        box-shadow: 10px 24px 54px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        position: absolute;
        bottom: 10%;
        left: -10%;
    }
    .position-ellips {
        width: -moz-fit-content;
        width: fit-content;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 55px;
    }

  }
  @media only screen and (max-width: 1024px) {
    .banner-sec {
        display: flex;
        align-items: center;
        padding-top: 180px;
    }
    h1{
        font-size: 40px;
        line-height: 47px;
    }
    h2{
        font-size: 32px !important;
        line-height: 40px !important;
    }
    .topimg {
        position: relative;
        z-index: 9;
        width: 59%;
    }
    .bgvector {
        bottom: -113px;
        left: 23px;
        z-index: 1;
        width: 103%;
    }
    .bg-circle {
        width: 200px;
        height: 200px;
        left: 20px;
        top: -122px;
        background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
        opacity: 0.6;
        filter: blur(30px);
        border-radius: 50%;
    }
  }
  @media only screen and (max-width: 991px) {
    .navbar.open {
        background: linear-gradient(0deg,#fff,#f0efff);
        height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    .open.navbar{
        display: block;
    }
    .open.sticky-top {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1020;
    }
    .position-ellips {
        width: -moz-fit-content;
        width: fit-content;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: -35px;
    }
    .signin-button {
        color: #fff;
        background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
        border-radius: 12px;
        padding: 14px 30px;
        display: inline-block;
    }
  }
  @media only screen and (max-width: 912px) {
    .position-ellips {
        width: -moz-fit-content;
        width: fit-content;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 25px;
    }
    .bottom-side-ellips, .up-side-ellips {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bottom-side-ellips {
        background: #29BB89;
        position: absolute;
        bottom: 15%;
        right: 38%;
    }
    .customer-service p {
        margin: 0;
        font-weight: 400;
        font-size: 10px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #000000;
    }
    .elips-image {
        background: #C4C4C4;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        width: 60%;
    }
  }
  @media only screen and (max-width: 767px) {
    .footer .quote{
        margin: auto;
    }
    .mobile-contact-form{
        display: block;
    }
    .mobile-contact-form .social-icon li {
        background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
        margin-right: 5px;
        text-align: center;
        border-radius: 5px;
        width: 40px;
        padding: 0;
    }
    .mobile-contact-form .social-icon li a {
        padding: 7px;
        display: flex;
        justify-content: center;
    }
    .mobile-contact-form.fotter-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-bottom: 10px;
    }
    .contact-us-left{
        display: none;
    }
    .log-in-form input, .log-in-form textarea{
        padding: 7px;
    }
    .email-lable {
        top: 9px;
        left: -3px;
    }
    .log-in-form{
        padding: 9px 15px;
    }

    .terms h2{
        font-size: 22px;
        color: #2e2e2e;
      }
      .terms h1{
        font-size: 32px;
        color: #2e2e2e;
      }
    .plan-card{
        box-shadow: none;
    }
    .plan .slick-arrow::before{
        color: #8A63F4 !important;
    }
    .plan .slick-prev {
        left: 40%;
        z-index: 9;
        bottom: -40px;
        top: auto;
        transform: translate(0px, -50%);
    }
    .plan .slick-prev:before, .slick-next:before {
        font-family: 'slick';
        font-size: 30px !important;
        line-height: 1;
        opacity: .75;
        color: white;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .plan .slick-next {
        right: 40%;
    z-index: 9;
    bottom: -40px;
    top: auto;
    transform: translate(0px, -50%);
    }
    .ellipse-container{
        display: block;
    }
    .plan-mobile{
        display: block !important;
    }
    .plan-desktop{
        display: none !important;
    }
    #fullpage{
        transform: none !important;
        transition: none !important;
        touch-action:unset !important ;
    }
    body{
        overflow: visible !important;
        overflow-x: hidden !important;
    }
    html{
        overflow: visible !important;
        overflow-x: hidden !important;
    }
    .fp-overflow{
        max-height: 100% !important;
        overflow: visible !important;
        overflow-x: hidden !important;
    }
    .log-in-form{
        margin: auto;
    }
    .fp-auto-height{
        height: auto !important;
    }
    div[name="Inner"]{
        transition:none !important;
    position: static !important;
    left: 0px;
    right: 0px;
    transform: none !important;
    }
    div[name="Warper"]{
        position: static !important;
    }
    div[name="Driver"]{
        height: auto !important;
    }
    .f-input{
        justify-content: center;
    }
    section{
        padding: 40px 0;
      }
      .faqsec {
        padding-bottom: 40px;
    }
    .banner-sec{
        padding-top: 90px;
        height: 200vh;
    }
    .fotter-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }
    .fotter-bottom ul li {
        color: #fff;
        display: block;
    }
    .banner-sec .col-md-6{
        height: calc(100vh - 90px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .topimg {
        position: relative;
        z-index: 9;
        width: 60%;
    }
    .bgvector {
        bottom: -113px;
        left: 43px;
        z-index: 1;
        width: 100%;
    }
    .plan {
         height: auto; 
        display: flex;
        align-items: center;
    }
    .pancard-gap {
        column-gap: 30px;
        justify-content: center;
        flex-direction: column;
    row-gap: 30px;
    align-items: center;
    }
    .center-card .plan-card {
        border-radius: 0px 0px 12px 12px;
        border: 2px solid #B090FF;
        margin-top: 26px;
    }
    .faqsec {
        /* height: calc(200vh - 90px); */
        display: flex;
        align-items: center;
        position: relative;
    }
    .position-ellips {
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        position: static;
        top: 0%;
        transform: translate(0, 0%);
        left: 0;
        /* height: calc(100vh - 90px); */
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .faqsec .col-md-7{
        /* height: calc(100vh - 90px); */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    footer ul li{
        display: inline-block;
        padding-right: 20px;
    }
    footer ul.text-end{
        text-align: center !important;
    }
    .social-icon{
        display: flex;
    }
    .social-icon a.me-4{
        margin-right: 0 !important;
    }
    .elips-image {
        background: #C4C4C4;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        width: 100%;
    }
    .bottom-side-ellips, .up-side-ellips {
        width: 66px;
        height: 66px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bottom-side-ellips {
        background: #29BB89;
        position: absolute;
        bottom: 15%;
        right: 0%;
    }
  }
  @media only screen and (max-width: 576px) {
    .terms p{
        text-align: justify;
    }
    img.app-image {
        width: 100px;
    }
    .copyright{
        font-size: 12px !important;
    margin-top: 3px !important;
    color: #9d9caf !important;
    }
    .fotter-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0;
    }
    h1{
        font-size: 40px;
    line-height: 56px;
    margin-top: 0;
    padding-right: 0;
    text-align: center;
    }
    .last-word {
        color: #9F7CFB;
      }
    .banner-sec p{
        margin-top: 8px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 30px;
    }
    .banner-sec ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: center;
    }
    .swiper-ver-slide .animate-img{
        animation: none !important;
        transform: none !important;
        opacity:  1;
    }
    .para-head{
        overflow: visible;
    }
    .transform-slide p{
        transform: none !important;
    }
    .slick-active .transform-slide p{
        animation:none !important
    }
    .slick-active .animate-img{
        transform: none !important;
    }
    .slick-active .transform-slide h2{
        animation: none !important;
        transform: none !important;
    }
    .transform-slide ul{
        animation: none !important;
        transform: none !important;
        opacity: 1 !important;
    }
    .swiper-ver-slide{
        height:auto;
    }
    .plancard {
        max-width: 290px;
        margin: auto;
    }
    .plan-card h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: right;
        letter-spacing: 0.6px;
        color: #3B3A40;
        margin: 0;
        padding: 0;
    }
    .swiper-ver-slide{
        display: block !important;
    }
    .accordion-button {
        padding: 15px 59px 15px 0 !important;
        background-color: transparent !important;
        color: #000 !important;
    }
    .para-div {
        margin-bottom: 0px;
        overflow: hidden;
        text-align: justify;
    }
    .accordion-body{
        text-align: justify;
    }
    #home .fp-overflow{
        overflow-x: visible !important;
    }
    .banner-sec .col-md-6{
        height: auto;
    }
    .padding-responsive{
        padding-top: 170px;
    }
     .faq-right {
        --mdb-gutter-x: 0rem !important;
    }
    .ellipse-container .accordion-button::after {
       position: absolute;
       right: 30px;
    }
    .ellipse-container .accordion-body {
        padding: 15px 15px 15px 0;
    }
    .service-ellips {
        background: #1789FB;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bottom-side-ellips, .up-side-ellips {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .position-ellips{
        width: 75%;
        display: none;
    }
    .line-bottom:before {
        position: absolute;
        content: "";
        width: 150px;
        height: 2px;
        background-color: #FF884B;
        bottom: -15px;
        display: none;
    }
    .faq-right .line-bottom{
        font-size: 32px !important;
        font-weight: 700;
        text-align: center;
        line-height: 45px !important;
        position: relative;
    }
    .faq-right::after {
        position: absolute;
        background: radial-gradient(50% 50% at 50% 50%,rgba(255,111,80,.21) 0,rgb(176, 144, 255,.21) 50%,rgb(138, 99, 244,.21) 100%);
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        filter: blur(44px);
        height: 14%;
        left: 5rem;
        top: 1rem;
        transform: matrix(1,-.1,-.1,-1,0,0);
        width: 60%;
        z-index: -9;
    }
    .banner-sec {
        padding-top: 0 !important;
        padding-bottom: 100px !important;
    }
    #fullpage{
        position: static !important;
    }
    .navbar.sticky-top {
        position: static;
        z-index: 1020;
        width: 100%;
    }
    .fp-viewing-Slider .navbar, .fp-viewing-Plan .navbar, .fp-viewing-Faq .navbar, .fp-viewing-Contact .navbar, .fp-viewing-undefined .navbar, .fp-viewing-Features .navbar, .fp-viewing-4 .navbar,.fp-viewing-Home .navbar {
        background: #ebe3ff;
        box-shadow: 0 16px 33px rgba(0,0,0,.08);
    }
    .plan-card:hover {
        border: 2px solid #D1D0D6;
    }
    h2 {
        font-size: 32px !important;
        line-height: 30px !important;
    }
    .footer h6 img{
        width: 92px;
    }
    footer p {
        color: #fff;
        font-size: 18.68px;
        font-weight: 500;
        line-height: 26px;
    }
    .toggle-switch{
        padding: 0;
    }
    .toggle-switch li p{
        font-size: 12px;
    }
    .swiper-ver-slide .row{
        width: auto !important;
    }
    .vertical-slider img {
        /* width: 50%; */
        margin: auto;
    }
    .vertical-slider .animate-img {
        max-width: 300px;
        max-height: 400px;
    }
    .swiper-ver-slide ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        /* justify-content: space-between; */
    }.slick-dots {
        position: absolute;
        bottom: 0px;
        display: block;
        width: -moz-fit-content !important;
        width: fit-content !important;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        right: 0;
        height: -moz-fit-content;
        height: fit-content;
        left: 50%;
        transform:translate(-50% , 0);
        top: 100%;
    }

    .vertical-slider h2 {
        font-size: 24px !important;
        line-height: 22px !important;
    }
    .banner-sec .col-md-6{
        text-align:left !important;
    }
    .behindimg {
        top: -84px;
        left: 25%;
        z-index: 2;
        height: 100%;
        animation: bounce .9s ease infinite alternate-reverse;
    }
    .bgvector {
        bottom: -60px;
        left: 5px;
        z-index: 1;
        width: 100%;
    }
    .bg-circle {
        width: 200px;
        height: 200px;
        left: 5px;
        top: -160px;
        background: linear-gradient(91.48deg, #8A63F4 0.29%, #B090FF 100%);
        opacity: 0.6;
        filter: blur(30px);
        border-radius: 50%;
    }
    .customer-service {
        left: 0;
    }
  }
  @media only screen and (max-width: 1024px) and (min-width: 992px)  {
    .topimg {
        position: relative;
        z-index: 9;
        width: 53%;
    }
    .bgvector {
        bottom: -51px;
        left: 56px;
        z-index: 1;
        width: 80%;
    }
    .plan-card-header {
        height: 75.81px;
        border-bottom: 1px dashed #D1D0D6;
        margin-bottom: 10px;
    }
    .plan-card {
        background: #FFFFFF;
        border: 1px solid #D1D0D6;
        box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding: 15px;
        min-height: 370px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 360px;
    }
    .plan .toggle-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 16px;
        margin-bottom: 35px;
    }
    .plan h2{
        margin: 5px;
    }
    .buy-now {
        color: #B3B1B7;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.2px;
        width: 100%;
        border: none;
        background: transparent;
        margin-top: 0px;
    }
  }
  @media only screen and (max-width: 374px) {
    .swiper-ver-slide{
        display: block !important;
    }
    img.app-image {
        width: 130px;
    }
    .vertical-slider .animate-img {
        width: 50%;
    }
  }